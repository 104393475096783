'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _rcTrigger = require('rc-trigger');

var _rcTrigger2 = _interopRequireDefault(_rcTrigger);

var _placements = require('./placements');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var Tooltip = function (_Component) {
  (0, _inherits3['default'])(Tooltip, _Component);

  function Tooltip() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3['default'])(this, Tooltip);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3['default'])(this, (_ref = Tooltip.__proto__ || Object.getPrototypeOf(Tooltip)).call.apply(_ref, [this].concat(args))), _this), _this.getPopupElement = function () {
      var _this$props = _this.props,
          arrowContent = _this$props.arrowContent,
          overlay = _this$props.overlay,
          prefixCls = _this$props.prefixCls;

      return [_react2['default'].createElement(
        'div',
        { className: prefixCls + '-arrow', key: 'arrow' },
        arrowContent
      ), _react2['default'].createElement(
        'div',
        { className: prefixCls + '-inner', key: 'content' },
        typeof overlay === 'function' ? overlay() : overlay
      )];
    }, _temp), (0, _possibleConstructorReturn3['default'])(_this, _ret);
  }

  (0, _createClass3['default'])(Tooltip, [{
    key: 'getPopupDomNode',
    value: function getPopupDomNode() {
      return this.refs.trigger.getPopupDomNode();
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          overlayClassName = _props.overlayClassName,
          trigger = _props.trigger,
          mouseEnterDelay = _props.mouseEnterDelay,
          mouseLeaveDelay = _props.mouseLeaveDelay,
          overlayStyle = _props.overlayStyle,
          prefixCls = _props.prefixCls,
          children = _props.children,
          onVisibleChange = _props.onVisibleChange,
          transitionName = _props.transitionName,
          animation = _props.animation,
          placement = _props.placement,
          align = _props.align,
          destroyTooltipOnHide = _props.destroyTooltipOnHide,
          defaultVisible = _props.defaultVisible,
          getTooltipContainer = _props.getTooltipContainer,
          restProps = (0, _objectWithoutProperties3['default'])(_props, ['overlayClassName', 'trigger', 'mouseEnterDelay', 'mouseLeaveDelay', 'overlayStyle', 'prefixCls', 'children', 'onVisibleChange', 'transitionName', 'animation', 'placement', 'align', 'destroyTooltipOnHide', 'defaultVisible', 'getTooltipContainer']);

      var extraProps = (0, _extends3['default'])({}, restProps);
      if ('visible' in this.props) {
        extraProps.popupVisible = this.props.visible;
      }
      return _react2['default'].createElement(
        _rcTrigger2['default'],
        (0, _extends3['default'])({
          popupClassName: overlayClassName,
          ref: 'trigger',
          prefixCls: prefixCls,
          popup: this.getPopupElement,
          action: trigger,
          builtinPlacements: _placements.placements,
          popupPlacement: placement,
          popupAlign: align,
          getPopupContainer: getTooltipContainer,
          onPopupVisibleChange: onVisibleChange,
          popupTransitionName: transitionName,
          popupAnimation: animation,
          defaultPopupVisible: defaultVisible,
          destroyPopupOnHide: destroyTooltipOnHide,
          mouseLeaveDelay: mouseLeaveDelay,
          popupStyle: overlayStyle,
          mouseEnterDelay: mouseEnterDelay
        }, extraProps),
        children
      );
    }
  }]);
  return Tooltip;
}(_react.Component);

Tooltip.propTypes = {
  trigger: _propTypes2['default'].any,
  children: _propTypes2['default'].any,
  defaultVisible: _propTypes2['default'].bool,
  visible: _propTypes2['default'].bool,
  placement: _propTypes2['default'].string,
  transitionName: _propTypes2['default'].string,
  animation: _propTypes2['default'].any,
  onVisibleChange: _propTypes2['default'].func,
  afterVisibleChange: _propTypes2['default'].func,
  overlay: _propTypes2['default'].oneOfType([_propTypes2['default'].node, _propTypes2['default'].func]).isRequired,
  overlayStyle: _propTypes2['default'].object,
  overlayClassName: _propTypes2['default'].string,
  prefixCls: _propTypes2['default'].string,
  mouseEnterDelay: _propTypes2['default'].number,
  mouseLeaveDelay: _propTypes2['default'].number,
  getTooltipContainer: _propTypes2['default'].func,
  destroyTooltipOnHide: _propTypes2['default'].bool,
  align: _propTypes2['default'].object,
  arrowContent: _propTypes2['default'].any
};
Tooltip.defaultProps = {
  prefixCls: 'rc-tooltip',
  mouseEnterDelay: 0,
  destroyTooltipOnHide: false,
  mouseLeaveDelay: 0.1,
  align: {},
  placement: 'right',
  trigger: ['hover'],
  arrowContent: null
};
exports['default'] = Tooltip;
module.exports = exports['default'];