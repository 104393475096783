module.exports={
  "peopleData": [
        { 
          "key":"4",
          "type":"person",
          "image":"assets/img/elliot-alderson-lg.jpg",
          "title":"Elliot Alderson",
          "subtitle":"Rami Malek",
          "ctas":[["Go To Bio","iconcss icon-person"],["  Go To Scene  ","iconcss icon-play-outline"]],
          "characterName": "Elliot Alderson",
          "actorName": "Rami Malek",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/3/3e/Elliot.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/345/window-height/345?cb=20150810201239",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/8/8f/RMalek.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/3744/window-height/4744?cb=20150806150119",
          "characterBio": "Elliot Alderson is a cybersecurity engineer and vigilante hacker who lives in New York City. He is played by Rami Malek. Elliot suffers from social anxiety disorder, clinical depression, delusions and paranoia. His internal life is revealed via voice-overs that provide insight into his mental state, his opinions of the people he encounters and the activity around him. These dialogues with the audience are designed to help us interpret Elliot's world, but given his mental illness, he is an unreliable narrator, leaving us unsure whether what he tells us we're seeing is actually what is happening. Typical of this is when Elliot hears everyone around him refer to E Corp as Evil Corp, reflecting his own opinion of the conglomerate.",
          "actorBio": "Rami Said Malek, born May 12, 1981, is an American actor. He was born and raised in Los Angeles and has an identical twin, Sami. He is of Egyptian descent; his parents are Coptic Christians who emigrated from Egypt before he was born. Malek studied theater performance and earned a Bachelor of Fine Arts degree at the University of Evansville. He is known for his role as the gay teenage next-door-neighbor, 'Kenny' on the FOX comedy series The War at Home; for his role as Merriell 'Snafu' Shelton in the HBO miniseries The Pacific; his role in 2013's 'Short Term 12' as Nate; and for his role as the pharaoh Ahkmenrah in the feature films Night at the Museum, Night at the Museum: Battle of the Smithsonian, and Night at the Museum: Secret of the Tomb. He also played Benjamin in the final installment of The Twilight Saga: Breaking Dawn. He also played Joshua (Josh) Washington in the hit 2015 video game, Until Dawn.",
          "otherWorks": [
            {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }
          ]
        },
        { 
          "key":"12",
          "type":"person",
          "image":"assets/img/mr-robot.jpg",
          "title":"Mr. Robot",
          "subtitle":"Christian Slater",
          "ctas":[["Go To Bio  ","iconcss icon-person"], ["  Go To Scene        ","iconcss icon-play-outline"]],
          "characterName": "Mr. Robot",
          "actorName": "Christian Slater",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/0/0f/MrRobot.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/1004/window-height/1004?cb=20150810201043",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/3/34/CSlater.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/300/window-height/300?cb=20150802021855",
          "characterBio": "Mr. Robot is the co-founder and leader of the hacker group fsociety, a revolutionary prophet who plans to bring down the world's largest conglomerate. Though he is intelligent and charismatic, Mr. Robot is also emotionally manipulative, and can be quick to violence. This has prompted comparisons to the behavior of militant cult leaders.  It eventually becomes clear that Mr. Robot is a construct within Elliot Alderson's mind. (“eps1.8_m1rr0r1ng.qt”) His persona is an idealized representation of Elliot's deceased father, Edward; where Edward was unwilling or unable to take action, Mr. Robot was created to inspire war against E Corp and incite historical change. In some situations, Mr. Robot will take control and act through Elliot, thus explaining his direct interactions with other people. ",
          "actorBio": "Christian Michael Leonard Slater (born August 18, 1969) is an American actor. He made his film debut with a small role in The Postman Always Rings Twice before playing a leading role in the 1985 film The Legend of Billie Jean. He played a monk's apprentice alongside Sean Connery in The Name of the Rose before gaining wider recognition for his breakthrough role in the cult film Heathers.",
          "otherWorks": [
             {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }

          ]
        },
        { 
          "key":"2",
          "type":"person",
          "image":"assets/img/darlene-alderson-lg.jpg",
          "title":"Darlene Alderson",
          "subtitle":"Carly Chaikin",
          "ctas":[["Go To Bio ","iconcss icon-person"], ["  Go To Scene ","iconcss icon-play-outline"]],
          "characterName": "Darlene Alderson",
          "actorName": "Carly Chaikin",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/1/11/Darlene.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/347/window-height/347?cb=20150810195444",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/c/ca/CChaikin.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/932/window-height/932?cb=20150806144631",
          "characterBio": "Darlene was born on November 5, 1990. She states she was 4 years old when she first saw Susan Jacobs when E Corp was vindicated in the Washington Township trial. Her father, Edward Alderson, died on February 28, 1995. Her mother, Magda, was abusive toward her and her brother. When she was 5, she was kidnapped by a woman during a family trip to an amusement park. She slept at the woman's house, in a pink bedroom, and awoke the next morning to the police. Darlene recalls the experience somewhat fondly. However, in contrast to her story, her father was dead by the time she turned five on November 5, 1995.",
          "actorBio": "Carly Hannah Chaikin (born March 26, 1990) is an American actress. She got her breakout role in the film The Last Song, and starred as Dalia Royce in the sitcom Suburgatory.",
          "otherWorks": [
             {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }
          ]
        },
        { 
          "key":"13",
          "type":"person",
          "image":"assets/img/angela-moss.jpg",
          "title":"Angela Moss",
          "subtitle":"Portia Doubleday",
          "ctas":[["Go To Bio   ","iconcss icon-person"], [" Go To Scene    ","iconcss icon-play-outline"]],
          "characterName": "Angela Moss",
          "actorName": "Portia Doubleday",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/d/d1/AMoss.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/629/window-height/629?cb=20150809224155",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/f/fc/PDoubleday.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/1277/window-height/1277?cb=20150806150730",
          "characterBio": "Angela Moss is a childhood friend of Elliot Alderson. She is portrayed by Portia Doubleday. Angela was born on February 27, 1988, in New Jersey to Phillip Price and Emily Moss, but Emily refused to be with Phillip, instead choosing to marry Donald Moss, who became her adopted father. She is an only child. Her mother died as a result of the Washington Township leak of 1993. In the aftermath, she became friends with Darlene and Elliot Alderson, with whom she shared a love for the book From the Mixed-Up Files of Mrs. Basil E. Frankweiler. Angela fondly remembers wanting to be like Claudia Kincaid, the story's adventurous heroine. She also remembers the Aldersons subjecting her to their favorite movie, The Careful Massacre of the Bourgeoisie, every Halloween.",
          "actorBio": "Angela was born on February 27, 1988, in New Jersey to Phillip Price and Emily Moss, but Emily refused to be with Phillip, instead choosing to marry Donald Moss, who became her adopted father. She is an only child. Her mother died as a result of the Washington Township leak of 1993. In the aftermath, she became friends with Darlene and Elliot Alderson, with whom she shared a love for the book From the Mixed-Up Files of Mrs. Basil E. Frankweiler. Angela fondly remembers wanting to be like Claudia Kincaid, the story's adventurous heroine. She also remembers the Aldersons subjecting her to their favorite movie, The Careful Massacre of the Bourgeoisie, every Halloween.",
          "otherWorks": [
             {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }

          ]
        },
        { 
          "key":"14",
          "type":"person",
          "image":"assets/img/trenton-lg.jpg",
          "title":"Trenton",
          "subtitle":"Sunita Mani",
          "ctas":[["Go To Bio    ","iconcss icon-person"], ["  Go To Scene    ","iconcss icon-play-outline"]],
          "characterName": "Trenton",
          "actorName": "Sunita Mani",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/1/1e/Trenton.png/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/265/window-height/265?cb=20150810142611",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/c/c7/SMani.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/497/window-height/750?cb=20160602025229",
          "characterBio": "Trenton's parents are Iranian immigrants who came to America for freedom. Her father, Arash, works 60-hour weeks to find tax loopholes for a millionaire art dealer. Her mother ran up a five-figure debt earning an online degree. She was born in Mashhad, Iran and later moved to Trenton, NJ where her younger brother Mohammed was born. The family lives together in Brooklyn, and she is a student at NYU. Having grown up in the city, she doesn't know how to drive. She has an uncle who lives in Connecticut. She would often trip when putting on her shoes at the mosque.",
          "actorBio": "Sunita Mani is an american actress and dancer. Her works include improv comedy, commercials, writings, and short ﬁlms. Her most important role in a series has been in Mr. Robot, where she portrays the character Trenton. She has a comedy dance group called Cocoon Central Dance Team and has appeared in many music videos including 'Turn Down for What'",
          "otherWorks": [
             {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }

          ]
        },
        { 
          "key":"15",
          "type":"person",
          "image":"assets/img/mobley-lg.jpg",
          "title":"Mobley",
          "subtitle":"Azhar Khan",
          "ctas":[[" Go To Bio","iconcss icon-person"], ["  Go To Scene ","iconcss icon-play-outline"]],
          "characterName": "Mobley",
          "actorName": "Azhar Khan",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/6/6d/Mobley.png/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/244/window-height/244?cb=20150826004026",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/0/08/AKhan.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/561/window-height/750?cb=20160607020812",
          "characterBio": "Mobley was born on January 2, 1984. He lives in Brooklyn. He is a fan of an obscure DJ from whom he took his hacker name. He even created an Angelfire page in DJ Mobley's honor. His father is named Ganesh, his mother Nazanin. His sister is named Sruthi. His brother Sandesh is a lawyer and Mets fan, whose wife is Elaine and daughter Rose.",
          "actorBio": "Azhar Khan was born in in Maharashtra, India, in January 7, 1984. He was raised in New York, USA. Azhar studied writing, animation and acting. Even though its most popular role has been Mobley from the T.V series Mr. Robot, Azhar has also worked on movies like 'Sleep Walk With Me' and 'Fresh Air 2: 2 Fresh 2 Furious'.",
          "otherWorks": [
            {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }

          ]
        },
        { 
          "key":"16",
          "type":"person",
          "image":"assets/img/romero-lg.jpg",
          "title":"Romero",
          "subtitle":"Ron Cephas Jones",
          "ctas":[["  Go To Bio","iconcss icon-person"], ["   Go To Scene    ","iconcss icon-play-outline"]],
          "characterName": "Romero",
          "actorName": "Ron Cephas Jones",
          "characterImage" :"https://vignette.wikia.nocookie.net/mrrobot/images/2/2b/Romero.jpg/revision/latest/window-crop/width/451/x-offset/0/y-offset/0/window-width/453/window-height/453?cb=20150807160301",
          "actorImage":"https://vignette.wikia.nocookie.net/mrrobot/images/7/74/RCephas.jpg/revision/latest/scale-to-width-down/200?cb=20160603160413",
          "characterBio": "Leslie Romero is a computer criminal, engineer, and biologist, specializing in phone phreaking and marijuana husbandry. He lives with his elderly mother, Nell Romero, and has a history with Sunil 'Mobley' Markesh. He is played by Ron Cephas Jones. Though Romero is highly knowledgeable, his thirst for fame and lack of commitment leads to conflict with other members of fsociety.",
          "actorBio": "Ron Cephas Jones is an American actor who plays Romero in the series Mr. Robot. He has also worked in several movies, his most known works being 'Half Nelson', 'He Got Game', and 'Sweet and Lowdown'.",
          "otherWorks": [
             {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }

          ]
        },
        { 
          "key":"35",
          "type":"person",
          "image":"assets/img/salesman-lg.jpg",
          "title":"Salesman",
          "subtitle":"Eric Martin Brown",
          "ctas":[["   Go To Bio","iconcss icon-person"],[" Go To Scene    ","iconcss icon-play-outline"]],
          "characterName": "Salesman",
          "actorName": "Eric Martin Brown",
          "characterImage": "assets/img/salesman-lg.jpg",
          "actorImage": "assets/img/eric-martin-brown-lg.jpg",
          "actorBio": "Eric Martin Brown was born on May 20, 1968 in Syracuse, New York, USA. He is an actor, known for The First Wives Club (1996), One Life to Live (1968) and 30 Rock (2006).",
          "characterBio": "A shoe salesman who is appalled by Angela’s choice to come in to buy shoes following the E-Corp EVP of Technology committing suicide on live TV. He expresses further disgust for Angela's willingness to keep her job working for E-Corp following the Fsociety hack fallout.",
          "otherWorks": [
            {
              "image": "assets/img/busters-mal-heart.jpg",
              "link": "https://www.rottentomatoes.com/m/busters_mal_heart/",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "55%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "71%"
            },
            {
              "image": "assets/img/short-term-12.jpg",
              "link": "https://www.rottentomatoes.com/m/short_term_12_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "93%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "99%"
            },
            {
              "image": "assets/img/aint-them-bodies-saints.jpg",
              "link": "https://www.rottentomatoes.com/m/aint_them_bodies_saints_2013",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "61%",
              "tomatometer": "assets/img/fresh.png",
              "tomatometerScore": "79%"
            },
            {
              "image": "assets/img/da-sweet-blood-of-jesus.jpg",
              "link": "https://www.rottentomatoes.com/m/da_sweet_blood_of_jesus",
              "rottenaudience": "assets/img/greenpopcorn.png",
              "rottenaudienceScore": "17%",
              "tomatometer": "assets/img/rotten.png",
              "tomatometerScore": "44%"
            },
            {
              "image": "assets/img/mr-robot-extra.jpg",
              "link": "https://www.rottentomatoes.com/tv/mr_robot",
              "rottenaudience": "assets/img/popcorn.png",
              "rottenaudienceScore": "92%",
              "tomatometer": "assets/img/tomato.png",
              "tomatometerScore": "94%"
            }
          ]
        },
      ]
}